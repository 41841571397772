import React from "react";
import Page from "../components/Page";
import { Container, Grid, Paper, Typography } from "@mui/material";
import background from "../assets/images/whatamidoing.webp";

const NotFoundPage = () => {
  return (
    <Page title="Page not found" description="Page not found">
      <Container>
        <section className="section" id="not-found">
          <Grid container mt={4} spacing={3}>
            <Grid item xs={6}>
              <Typography
                fontSize={40}
                sx={{ textAlign: "center", fontWeight: "bolder" }}
                variant="h1"
              >
                404 PAGE NOT FOUND
              </Typography>
              <Typography
                fontSize={20}
                sx={{ marginTop: "20px", textAlign: "center" }}
                variant="body1"
              >
                Oh no! Something didn't go as expected. Please check that you
                typed the address correctly, you can try to go back to your
                previous page.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <img
                  alt="404 error"
                  className="img-responsive"
                  src={background}
                />
              </Paper>
            </Grid>
          </Grid>
        </section>
      </Container>
    </Page>
  );
};

export default NotFoundPage;
